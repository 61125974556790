
export const formatAmount = value => (value / 100).toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    .replace(/\.00$/, '');

export const sanitizeHTML = str => str
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");