import React, { createContext, useContext, useReducer } from 'react';

const I18nContext = createContext();

const i18nReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LANG':
            localStorage.setItem('i18n', action.payload.lang);
            return {
                ...state,
                ...action.payload
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

export const I18nProvider = ({ children }) => {
    const lang = localStorage.getItem('i18n') || 'ru';
    const [state, dispatch] = useReducer(i18nReducer, { lang });

    const value = { state, dispatch };
    return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>;
}

export const useI18n = () => {
    const context = useContext(I18nContext);
    if (context === undefined) {
        throw new Error('useI18n must be used within a I18nProvider');
    }
    return context;
}
