import styles from "./header.module.css";
// import { useConfig } from 'ConfigContext.js';
import { useI18n } from "I18nContext";

import i18n from "./i18n.json";
import { sanitizeHTML } from "utils";

export default function Header({ transaction }) {
    // const { config = false } = useConfig();
    const { state } = useI18n();
    const { lang } = state;

    const id = transaction.id;
    const title = transaction.merchant.name;
    const order = transaction.order_id || null;
    const description = transaction.description && transaction.description.trim() !== "" ? 
        transaction.description 
        : 
        null;

    return (
        <div className={styles.Header}>
            <h1>{title}</h1>
            {order ? 
                    <h2>{i18n[lang].order} #{order}</h2> 
                    : 
                    <h2>{i18n[lang].payment} #{id}</h2>}
            { description && <h3>{sanitizeHTML(description)}</h3>}    
        </div>
    );
}