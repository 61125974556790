import React, { useEffect, useRef, Suspense, useState } from 'react';
import { getPayment } from 'Api';
import { SECURE_URL } from "Api/config";
import Spinner from './Components/Spinner/Spinner';
import TransactionStatus from 'Components/TransactionStatus/TransactionStatus';

import { ConfigProvider, useConfig } from './ConfigContext';
import { I18nProvider, useI18n } from "./I18nContext";

const ByCard = React.lazy(() => import('Components/PaymentByCard/PaymentByCard'));

// Constants
const UI_TIMEOUT = 10;
const REQUEST_TIMEOUT = 2500;

const STATUS_ERROR = -1;
const STATUS_FORM = 0;
const STATUS_3DS = 150;
const STATUS_SUCCESS = 500;
const STATUS_FAIL = 400;
const ACTIVE_STATUSES = [STATUS_FORM, STATUS_SUCCESS, STATUS_FAIL, STATUS_3DS];

// Доступные платежные методы
const PAYMENT_METHODS = {
  2: transaction => <ByCard payment={transaction} />
};

const PaymentMethod = ({ data }) => (
  <Suspense fallback={<Spinner />}>
    {PAYMENT_METHODS[data.payment_method] ? PAYMENT_METHODS[data.payment_method](data) : null}
  </Suspense>
);

function App() {
  const [state, setState] = useState({
    transaction: null,
    transactionStatus: null
  });

  const timeoutID = useRef(null);
  const intervalsCounter = useRef(0);
  const { fetchConfig, loadFromLS } = useConfig();
  const { dispatch } = useI18n();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const transactionID = params.get('id');
    const configID = params.get('config');
    const lang = params.get('i18n');

    configID ? fetchConfig(configID) : loadFromLS();
    lang && dispatch({ type: 'SET_LANG', payload: { lang } });

    if (!transactionID) {
      setState(prevState => ({ ...prevState, transactionStatus: STATUS_ERROR }));
      return;
    }

    const getTransaction = async () => {
      try {
        intervalsCounter.current = intervalsCounter.current + 1;
        if (intervalsCounter.current > UI_TIMEOUT) {
          setState(prevState => ({ ...prevState, transactionStatus: STATUS_ERROR }));
          return;
        }

        const response = await getPayment(transactionID);
        const { status } = response;

        document.title = response.merchant.name + " — платёж #" + response.id;

        setState({
          transaction: response,
          transactionStatus: status
        });

        if (!ACTIVE_STATUSES.includes(status)) {
          timeoutID.current = setTimeout(getTransaction, REQUEST_TIMEOUT);
        }
      } catch (error) {
        setState(prevState => ({ ...prevState, transactionStatus: STATUS_ERROR }));
      }
    };

    getTransaction();

    return () => {
      if (timeoutID.current) clearTimeout(timeoutID.current);
    };
  }, [fetchConfig, loadFromLS]);

  switch (state.transactionStatus) {
    case STATUS_FORM:
      return <PaymentMethod data={state.transaction} />;
    case STATUS_3DS:
      window.location.href = SECURE_URL + "?id=" + state.transaction.id;
      return null;
    case STATUS_SUCCESS:
    case STATUS_FAIL:
    case STATUS_ERROR:
      return <TransactionStatus transaction={state.transaction} />;
    default:
      return <Spinner />;
  }
}

export default function () {
  return (
    <ConfigProvider>
      <I18nProvider>
        <App />
      </I18nProvider>
    </ConfigProvider>
  );
}
