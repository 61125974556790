import React, { createContext, useContext, useState, useCallback } from 'react';

const ConfigContext = createContext(null);

export const useConfig = () => {
    return useContext(ConfigContext);
};

export const ConfigProvider = ({ children }) => {
    const [config, setConfig] = useState(null);

    const updateColors = colors => {
        if (!colors || typeof colors !== 'object') return;

        Object.keys(colors).forEach(variable => {
            const color = colors[variable];
            color && document.documentElement.style.setProperty(variable, color);
        });
    };

    const fetchConfig = useCallback(async configID => {
        try {
            const response = await fetch(`https://api.npoint.io/${configID}`);
            const data = await response.json();

            if (data) {
                setConfig(data);

                if (data.store.browser) {
                    localStorage.setItem('app_config', JSON.stringify(data));
                } else {
                    localStorage.removeItem("app_config");
                }

                data.colors && updateColors(data.colors);
            }
        } catch (error) {
            console.error(`Error: fetchConfig(${configID}):`, error);
        }
    }, []);

    const loadFromLS = useCallback(() => {
        const storedConfig = localStorage.getItem('app_config');
        if (storedConfig) {
            const parsedConfig = JSON.parse(storedConfig);
            setConfig(parsedConfig);

            parsedConfig.colors && updateColors(parsedConfig.colors);
            if (parsedConfig.store.disposable === true) localStorage.removeItem("app_config");
        }
    }, []);

    return (
        <ConfigContext.Provider value={{ config, fetchConfig, loadFromLS }}>
            {children}
        </ConfigContext.Provider>
    );
};
