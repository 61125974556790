import styles from "./price.module.css";
import { formatAmount } from "utils";

export default function Price ({ transaction }) {
    const { amount, customer_commission = 0, currency_symbol = "?" } = transaction;
    const uiAmount = formatAmount(amount + customer_commission);

    return (
        // TODO: Добавить блок с описанием платежа справа от ценника
        <div className={styles.Price}>
            <span className={styles.Currency}>{currency_symbol}</span>
            <span className={styles.Amount}>{uiAmount}</span>
        </div>
    );
}

