import Container from "Components/Container/Container";
import Price from "Components/Price/Price";
import Header from "Components/Header/Header";
import Alert from "Components/Alert/Alert";
import buttonsStyles from "Components/Form/buttons.module.css";
import { useI18n } from "I18nContext";
import { useConfig } from 'ConfigContext.js';

import i18n from "./i18n.json";

export default function TransactionStatus ({ transaction }) {
  const { config = false } = useConfig();
  const { return_url = false, status, id } = transaction || {};

  if (transaction.merchant.host2host === true) {
    window.location.href = return_url;
    return "";
  }

  if (return_url && config && config?.host2host === true) {
    window.location.href = return_url;
    return "";
  }

  return (
    <Container>
      {transaction && <Header transaction={transaction} />}
      {transaction && <Price transaction={transaction} />}
      <Status code={status} id={id} />
      {status && status !== 500 && <HelpMeButton url={transaction.return_url}/>}
      {status && status === 500 && <ReturnButton url={transaction.return_url}/>}
    </Container>
  );
}

const Status = ({ code }) => {
  const { state } = useI18n();
  const { lang } = state;

  if (!code) return <Alert title={i18n[lang].alert.title.error}  
    message={i18n[lang].alert.message['404']} type="danger" />;
  
  switch (code) {
    case 500:
      return <Alert title={i18n[lang].alert.title.success} 
        message={i18n[lang].alert.message['200']} type="success" />;
    case 400:
      return <Alert title={i18n[lang].alert.title.error} 
        message={i18n[lang].alert.message['4xx']} type="danger" />;
    default:
      return <Alert title={i18n[lang].alert.title.warning} 
        message={i18n[lang].alert.message['wtf']} type="danger" />;
  }
};

// TODO: Если указан return_url, то уходим на него передавая туда в параметр id транзакции. 
// Иначе показываем свой сакцесс-компонент и ссылку на сайт мерчанта, чтобы покупатель мог на него «вернуться».
const ReturnButton = ({ url = "#" }) => {
  const { state } = useI18n();
  const { lang } = state;

  return (
    <div className={buttonsStyles.Buttons}>
      <a href={url} className={`${buttonsStyles.Button}`} type="success">{i18n[lang].button.label}</a>
    </div>
  )
};

// TODO: собираем данные транзакции, создаем в тикет для мерчанта и отправляем уведомление;
const HelpMeButton = ({ url = "#" }) => {
  const { state } = useI18n();
  const { lang } = state;

  return (
    <div className={buttonsStyles.Buttons}>
      <a href={url} className={`${buttonsStyles.Button}`} type="danger">{i18n[lang].button.label}</a>
    </div>
  )
};